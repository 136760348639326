import { AllCommunityModule, ModuleRegistry } from 'ag-grid-community'; 

import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import api from "../services/api";
ModuleRegistry.registerModules([AllCommunityModule]);

const Users = () => {
    const [users, setUsers] = useState([]);
    
    useEffect(() => {
        api.getUsers().then((response) => {
            setUsers(response.data)
        }).catch(() => {
            setUsers([])
        })  
    }, []);

    const columnDefs = [
        { headerName: "Name", field: "name" },
        { headerName: "Email", field: "email" },
        { headerName: "Plans Created", field: "plans_count"},
        { headerName: "Plans Completed", field: "completed_plans_count" }
    ];
    if (!users) {
        return null;
    }
    return  <div className="p-8">
        <h1 className="font-bold text-xl text-black mb-8">Users</h1>
        <div
            className="mb-8"
            // define a height because the Data Grid will fill the size of the parent container
            style={{ height: 500 }}
        >
        <AgGridReact columnDefs={columnDefs} rowData={users} />
    </div>
    <div>
        Plans Created - Total: {users.reduce((acc, user) => acc + user.plans_count, 0)}<br/>
        Plans Completed - Total: {users.reduce((acc, user) => acc + user.completed_plans_count, 0)}
    </div>
    </div>
}

export default Users;