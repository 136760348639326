import './App.css'
import Plan from './Pages/Plan'
import Register from './Pages/Register'
import Resources from './Pages/Resources'
import { useAtom } from 'jotai';
import { redirectAtom, userAtom } from './state';
import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useMatch } from "react-router-dom";
import api from './services/api';
import Login from './Pages/Login';
import ForgotPassword from './Pages/ForgotPassword';
import ResetPassword from './Pages/ResetPassword';
import Users from './Pages/Users';

function App() {
  const [user, setUser] = useAtom(userAtom)
  const [ready, setReady] = useState(false)

    useEffect(() => {
        api.getUser().then((response) => {
            setUser(response.data)
            setReady(true)
        }).catch(() => {
            setReady(true)
        })  
    }, [])
    if (!user && !ready) {
        return null;
    }
    
  return (
    <BrowserRouter>
     <Routes>
     <Route element={<UnauthRoute />}>
          <Route path="/login" element={<Login/>} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/register" element={<Register />} />
      </Route>
      <Route element={<AuthRoute />}>
        <Route path="/" element={<Plan />} />
        <Route path="/users" element={<Users />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/plan/:token" element={<Plan />} />
      </Route>
     </Routes>
    </BrowserRouter>
  )
}

function AuthRoute() {
  const [user] = useAtom(userAtom);
  const [redirect, setRedirect] = useAtom(redirectAtom);
  const matchPlan = useMatch('/plan/:token');
  const matchResources = useMatch('/resources');

  if (user){
    if (redirect){
      window.location.pathname = redirect;
    }
    return <div>        
        <Outlet />
    </div> 
  }
  if (matchPlan  || matchResources) {
    setRedirect(window.location.pathname);

  }
  return <Navigate to="/register" />;
}

function UnauthRoute() {
  const [user] = useAtom(userAtom);
  return user ? <Navigate to="/" /> : <Outlet />;
}


export default App