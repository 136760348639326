import { Field, Form, Formik } from "formik";
import Button from "../Components/Button";
import FormikField from "../Components/Forms/FormikField";

function Join({onJoin, plan}) {

    const handleSubmit = (values) => {
        onJoin(values.role)
    }
    return <div>
        <h1 className="font-bold text-2xl text-center mb-6">Join</h1>
        <p className="mb-4">Join {plan.name}'s Plan by selecting your role below.</p>
        <Formik initialValues={{role: "mentor"}} onSubmit={handleSubmit}>
            <Form>   
                <FormikField name="role" title="Role" instructions={[]}>
                    <Field as="select" name="role" className="w-full p-4 border-0 rounded-md mb-4 bg-neutral-100">
                        <option value="mentor">Mentor</option>
                        <option value="youth">Youth</option>
                    </Field>
                </FormikField>
                <Button onClick={onJoin}>Join</Button>
            </Form>
        </Formik>        
    </div>
  }
  
  export default Join
  